<template>
  <base-section id="comingsoon">
    <div
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false"
      data-aos-anchor-placement="top-center"
    >
      <base-section-heading color="grey lighten-2" title="Coming Soon" />
      <v-container
        class="d-flex justify-center align-center"
        style="height: 50vh"
      >
        <v-img
          src="@/assets/construction.jpg"
          style="max-width: 600px; width: 100%"
        />
      </v-container>
    </div>
  </base-section>
</template>
<script>
export default {
  name: "SectionComingSoon",
};
</script>
